.trackMyorderSection{
    .track-order-section{
        /* display: flex;
        justify-content: center; */
        border: 0;
        margin: 0 auto;
        text-align: center;
    }
    .trackinput{
        border-bottom: 1px solid #d4ddf4 !important;
        border: none;
        border-radius: unset;
        width: 100%;
        color: #333 !important;
    }
    :focus {
        border:0;
        outline:0;
    }
    .form-row-first{
        margin-bottom: 15px;
        vertical-align: top;
        display: flex;
        overflow: hidden;
        width: 100%;
    }
    select{
        padding-top: 10px;
        font-size: 12px;
        font-weight: 600;
        option{
            
        }
    }
    .button-link{
        background: #c90101;
        border: none;
        border-radius: 0;
        box-shadow: none;
        color: #fff;
        display: inline-block;
        font-size: 13px;
        font-weight: 700;
        padding: 0 16px;
        text-shadow: none;
        text-transform: uppercase;
        transition: all .3s ease 0s;
        white-space: nowrap;
        line-height: 38px;
        height: 38px;
    }
    .delivery-track{
        width: 100%;
    }
    .track-title{
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .track-order-card{
        border-top: 4px solid #0055b8;
        box-shadow: 0px 1px 8px #c5c5c5;
        border-radius: 12px;
        padding: 14px;
    }
    ::placeholder{
        font-size: 12px;
        font-weight: 600;
    }
}
