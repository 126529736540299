.ContactUs{
    font-family: 'Poppins',sans-serif;
    .contactusimg{
        padding-bottom: 5%;
        //width: 100%;
        //margin-top: -2%;
        //position: absolute;
        background-image: url(..//../public/Assets/contactusimg.webp);
    }
    .contact{
        position: relative;
        margin-top: -23%;
    }
    .ContactUsstrong{
        background: #c90101;
        color: #fff;
        font-size: 48px;
        font-weight: 400;
        padding: 0px;
        margin-top: 15%;
        margin-left: 10%;
        margin-right: 10%;
        padding-left: 2%;
        //font-weight: 700;
        //width: 33.33333333%;
    }
    .information{
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        padding: 0 90px;
        margin-top: 5%;
        padding-left: 0%;
    }
    strong{
        //font-weight: bolder;
    }
    b, strong {
        font-weight: bolder;
    }
    .Androidimg{
        img.g-play {
            width:26%;
        }
        img.app-store {
            width:28%;
        } 
    }
    .GetItOn{
       
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #333333;
        padding-top: 2%;
    }
    p{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #333333;

    }
    
    @media screen and (max-width: 2560px){
        .ContactUsstrong{
            margin-top: 14%;
        }
       
        
    }
    @media screen and (max-width: 991px){
        .ContactUsstrong{
            padding-left: 1%;
            margin-top: 8%;
            font-size: 32px;
            margin-left: 1%;
            margin-right: 1%;
            padding: 5px;
            //padding:unset
            
        }
        .information{
            padding-left: 3%;
            margin-top: -1%;
        }
        
        .hrlineus{
            color: #fff;
            margin-left: 1%;
            margin-right: 1%;
        }
        .contactfill{
            padding-top: 5%;
            
        }
        
    }
    @media screen and (max-width: 425px){
       
        
    }
   
    @media screen and (max-width: 320px){
        
    } 
}



.responsive-iframe {
    width: 100%;
    height: 320px;
  }